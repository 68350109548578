<script>
import FeUserDeliveryAddressNew from './FeUserDeliveryAddressNew'
import NotifyService from '../../../services/NotifyService'

export default {
  name: 'FeUserDeliveryAddressEdit',
  extends: FeUserDeliveryAddressNew,
  methods: {
    getFeUserDeliveryAddress () {
      this.$store.dispatch('feUserDeliveryAddress/fetchOne', this.$route.params.id)
        .then(() => {
          this.feUserDeliveryAddress = this.$store.getters['feUserDeliveryAddress/detail']
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage('Please fill all required fields')
        return
      }

      // Check if user exists
      await this.$store.dispatch('feUser/fetchOne', this.feUserDeliveryAddress.feUser)
        .then(() => {
          if (this.$store.getters['feUser/error'] !== null) {
            NotifyService.setErrorMessage(this.$store.getters['feUser/error'])
          }
        })
        .catch(error => console.log(error))
      if (this.$store.getters['feUser/error'] !== null) {
        return
      }

      this.$store.dispatch('feUserDeliveryAddress/update', this.prepareFeUserDeliveryAddressRequest(this.feUserDeliveryAddress))
        .then(() => {
          if (this.$store.getters['feUserDeliveryAddress/error'] === null) {
            this.getFeUserDeliveryAddress()
            NotifyService.setSuccessMessage(this.$t('feUser.message.updated_record'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['feUserDeliveryAddress/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.getFeUserDeliveryAddress()
  }
}
</script>
